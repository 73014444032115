import "../styles/confirm-close-modal.scss";
import { Modal } from "./modal";
import React from "react";

export function ConfirmCloseModal({
  onDelete,
  onSaveDraft,
  onCancel,
  showConfirmDelete,
}) {
  return (
    <Modal className="confirm-close-modal">
      {showConfirmDelete && (
        <>
          <h4>Are you sure you want to do this?</h4>
          <div className="detail-delete" onClick={onDelete}>
            Delete Message
          </div>
        </>
      )}
      {!showConfirmDelete && (
        <>
          <h4>
            Are you sure you want to discard your Message?
            <br />
            All unsaved changes will be lost?
          </h4>
          <div id="discard" className="delete" onClick={onDelete}>
            Discard Message
          </div>
          <div id="save-draft" className="save-draft" onClick={onSaveDraft}>
            Save Draft
          </div>
        </>
      )}
      <div className="cancel" onClick={onCancel}>
        Cancel
      </div>
    </Modal>
  );
}
