import "../styles/edit-person-modal.scss";
import React from "react";
import { GuiTextbox } from "./global-ui/gui-textbox";
import { GuiSelect } from "./global-ui/gui-select";
import { GuiCheckbox } from "./global-ui/gui-checkbox";
import { useUpdateState } from "../utilities/utilities";
import actions from "../actions/actions";
import { Modal } from "./modal";
import { GuiButton } from "./global-ui/gui-button";
import { useOnFirstLoad } from "../utilities/hooks";
import { useSelector } from "react-redux";
import ValidationContext, { useValidation } from "./global-ui/validation";
import { UserRole } from "../utilities/constants";

export function EditPersonModal({ userId, onClose }) {
  let [person, updatePerson] = useUpdateState({});
  let [state, updateState] = useUpdateState({});
  let userTypeOptions =
    useSelector((store) => actions.user.loadUserTypes()) || [];
  let user = useSelector((store) => store.user);
  let validation = useValidation(validate, []);

  useOnFirstLoad(() => {
    actions.people.getPersonDetails(userId).then((result) => {
      if (!result.success) return;

      updatePerson(result.person);
      updateState("perms", result.permissions);
    });
  });

  function validate() {
    let errors = {};

    if (person.userType >= UserRole.Staff && person.phoneCode) {
      if (person.phoneCode.length < 6)
        errors["phoneCode"] = "Phone code must be 6 digits";
      if (!person.phonePin || person.phonePin.length < 6)
        errors["phonePin"] = "Phone pin must be 6 digits";
    }

    return errors;
  }

  async function savePerson() {
    if (!validation.allValid()) return validation.setShow(true);

    updateState("saving", true);

    let result = await actions.people.savePerson(person);
    if (result && result.success) onClose(true);
    else updateState("saving", false);
  }

  let userIsAdmin = user.userType === UserRole.Admin;
  let showUsername =
    person.userType === UserRole.Admin ||
    (person.userType === UserRole.Staff && person.staffCanLogin) ||
    (person.userType === UserRole.Member && person.membersCanLogin);
  if (!userIsAdmin)
    userTypeOptions = userTypeOptions.filter(
      (x) =>
        x.value < user.userType ||
        ((user.customerId === 3182 || user.customerId === 8121) &&
          x.value === 3 &&
          user.userType === 3)
    );

  return (
    <ValidationContext.Provider value={validation}>
      <Modal
        className={
          "plain-modal edit-person-modal" + (person.id ? "" : " loading")
        }
      >
        <h3>Edit User</h3>
        <i className="fa fa-times close" onClick={(e) => onClose(false)}></i>

        {/* Name & UniqueID */}
        <GuiTextbox
          label="First Name"
          name="firstName"
          value={person.firstName}
          onChange={updatePerson}
        />
        <GuiTextbox
          label="Last Name"
          name="lastName"
          value={person.lastName}
          onChange={updatePerson}
        />
        <GuiTextbox
          label="ID"
          name="uniqueId"
          value={person.uniqueId}
          onChange={updatePerson}
        />

        {/* Address */}
        <GuiTextbox
          label="Address"
          name="address"
          value={person.address}
          onChange={updatePerson}
          disabled={person.disableAddress}
        />
        <GuiTextbox
          label="City"
          name="city"
          className="city"
          value={person.city}
          onChange={updatePerson}
          disabled={person.disableAddress}
        />
        <GuiTextbox
          label="State"
          name="state"
          className="state"
          value={person.state}
          onChange={updatePerson}
          disabled={person.disableAddress}
        />
        <GuiTextbox
          label="Zip"
          name="zip"
          className="zip"
          value={person.zip}
          onChange={updatePerson}
          disabled={person.disableAddress}
        />

        {/* User & Permissions */}
        {showUsername && (
          <>
            {person.showUserType && (
              <GuiSelect
                label="User Type"
                name="userType"
                value={person.userType}
                options={userTypeOptions}
                onChange={(name, option) => updatePerson(name, option.value)}
              />
            )}
            <GuiTextbox
              label="Username"
              name="username"
              value={person.username}
              onChange={updatePerson}
            />
          </>
        )}

        {/* Faculty and above get phone codes */}
        {person.userType >= UserRole.Staff && (
          <>
            <GuiTextbox
              label="Phone Code"
              name="phoneCode"
              value={person.phoneCode}
              onChange={updatePerson}
              format="digits"
            />
            <GuiTextbox
              label="Phone Pin"
              name="phonePin"
              value={person.phonePin}
              onChange={updatePerson}
              format="digits"
            />
          </>
        )}

        {/* Special permission overrides */}
        {userIsAdmin && showUsername && person.userType === UserRole.Admin && (
          <div className="checkbox-row">
            <GuiCheckbox
              label="Send Messages Only"
              name="sendAlertsOnly"
              value={person.sendAlertsOnly}
              onChange={updatePerson}
            />
          </div>
        )}
        {userIsAdmin && showUsername && person.userType === UserRole.Staff && (
          <div className="checkbox-row">
            <GuiCheckbox
              label="Can set passwords"
              name="superFaculty"
              value={person.superFaculty}
              onChange={updatePerson}
            />
          </div>
        )}

        {/* Priority */}
        {person.showChangePriority && (
          <div className="checkbox-row">
            <GuiCheckbox
              label="Make high priority"
              name="highPriority"
              value={person.highPriority}
              onChange={updatePerson}
            />
          </div>
        )}

        {/* Do Not Delete */}
        <div className="checkbox-row">
          <GuiCheckbox
            label="Do not delete on import"
            name="doNotDeleteOnImport"
            value={person.doNotDeleteOnImport}
            onChange={updatePerson}
          />
        </div>

        <div className="buttons">
          <GuiButton className="btn-outline" onClick={(e) => onClose(false)}>
            Cancel
          </GuiButton>
          <GuiButton onClick={savePerson} loading={state.saving}>
            Update
          </GuiButton>
        </div>
      </Modal>
    </ValidationContext.Provider>
  );
}
