import React, { useEffect, useState } from "react";
import ProfileCard from "../components/profile-card";
import PageLayout from "../components/page-layout";
import RecentMessages from "./RecentMessages";
import "../styles/dashboard.scss";
import GroupMembership from "./GroupMembership";
import EditProfile from "./EditProfile";
import AddContactPopup from "./AddContactPopup";
import { SettingsApi } from "../actions/settings-action";
import { useOnFirstLoad } from "../utilities/hooks";
import { isMobile, useIsDesktop } from "../utilities/constants";
import BottomNavBar from "./BottomNavBar";
import MessageDropdown from "./MessageDropdown";
import DeleteContactPopup from "./DeleteContactPopup";
import RecentMessagePreviewPopup from "./RecentMessagePreviewPopup";
import EditPreferencePopup from "./EditPreferncePopup";
import user from "../actions/user-actions";
import { useUpdateState } from "../utilities/utilities";
import { Tooltip } from "react-bootstrap";
import GroupMembershipPreviewPopup from "./GroupMembershipPreviewPopup";
import { GuiTooltip } from "../components/global-ui/gui-tooltip";

export default function Dashboard() {
  const [isEditing, setIsEditing] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [languageResponse, setLanguageResponse] = useState([]);
  const [withVoiceId, setWithVoiceId] = useState([]);
  const [profileCardKey, setProfileCardKey] = useState(0);
  const [rmCardKey, setRmCardKey] = useState(0);
  const [editedContact, setEditedContact] = useState({});
  const [deletedContact, setDeletedContact] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewModalData, setPreviewModalData] = useState({});
  const [isEditPreference, setEditPreference] = useState(false);
  const [isComponentDisabled, setComponentDisabled] = useState(false);
  const [preferenceValue, setPreferenceValue] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [groupPreviewModal, setGroupPreviewModal] = useState(false);
  const [groupPreviewModalData, setGroupPreviewModalData] = useState({});
  let [state, updateState] = useUpdateState(() => {
    return {
      totalCredits: "",
      remainingCredits: "",
      billingCycle: "Monthly",
    };
  });
  const disableOtherComponent = () => {
    setComponentDisabled(!isComponentDisabled);
  };
  const openPreview = (items) => {
    setPreviewModal(!previewModal);
    setPreviewModalData(items);
    disableOtherComponent(!isComponentDisabled);
  };
  const openGroupPreview = (items) => {
    setGroupPreviewModal(!groupPreviewModal);
    setGroupPreviewModalData(items);
    disableOtherComponent(!isComponentDisabled);
  };
  const handlePreviewClose = () => {
    setPreviewModal(!previewModal);
    disableOtherComponent(!isComponentDisabled);
    handleRMCardKey();
  };
  const handleGroupPreviewClose = () => {
    setGroupPreviewModal(!groupPreviewModal);
    disableOtherComponent(!isComponentDisabled);
  };
  const handlePreferenceClose = () => {
    setEditPreference(!isEditPreference);
    disableOtherComponent(!isComponentDisabled);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setEditedContact({});
  };
  const handleProfileEdit = () => {
    setDisplayModal(!displayModal);
    disableOtherComponent(!isComponentDisabled);
  };
  const handleAddContact = () => {
    setContactModal(!contactModal);
    disableOtherComponent(!isComponentDisabled);
  };
  const onEditPreference = (items) => {
    setEditPreference(!isEditPreference);
    disableOtherComponent(!isComponentDisabled);
    setPreferenceValue(items);
  };
  const handleProfileCardKey = () => {
    setProfileCardKey((prevKey) => prevKey + 1);
  };
  const handleRMCardKey = () => {
    setRmCardKey((prevKey) => prevKey + 1);
  };
  const onDeleteClick = (items) => {
    setDeleteModal(!deleteModal);
    setDeletedContact(items);
  };
  useOnFirstLoad(() => {
    async function getPreferredLanguage() {
      let response = await SettingsApi.getContactLanguage();
      if (response.success) {
        let languageResponse = response.languages;
        setLanguageResponse(languageResponse);

        let withVoiceId = languageResponse.filter((item) =>
          item.hasOwnProperty("voiceId")
        );
        setWithVoiceId(withVoiceId);
      }
    }

    getPreferredLanguage();
    getAlertCredit();
  });
  useEffect(() => {
    if (isOpen) {
      const tooltip = document.querySelector(".gui-tooltip");
      const icon = document.querySelector(".mobile-title-progress-icon");
      const iconRect = icon.getBoundingClientRect();
      tooltip.style.top = `${iconRect.bottom}px`;
      tooltip.style.left = `${
        iconRect.left + iconRect.width / 2 - tooltip.offsetWidth / 2
      }px`;
    }
  }, [isOpen]);
  async function getAlertCredit() {
    let result = await user.getAlertCredit();
    if (result.success) {
      updateState({
        totalCredits: result.totalCredits,
        remainingCredits: result.remainingCredits,
        billingCycle: result.billingCycle,
      });
      return result;
    }
  }
  const onEditContact = (items) => {
    setEditedContact(items);
    handleAddContact();
  };
  const onDeleteClose = () => {
    setDeleteModal(!deleteModal);
  };
  const onDeleteSuccess = () => {
    setDeleteModal(!deleteModal);
    handleAddContact();
    handleEditToggle();
    handleProfileCardKey();
  };
  const onEditSuccess = () => {
    handleAddContact();
    handleProfileCardKey();
    handleEditToggle();
  };

  return (
    <>
      {displayModal && (
        <EditProfile
          displayModal={displayModal}
          onCloseClick={handleProfileEdit}
          onSucessfullAdd={handleProfileCardKey}
        ></EditProfile>
      )}
      {contactModal && (
        <AddContactPopup
          contactModal={contactModal}
          onClose={handleAddContact}
          textPreferredLanguage={languageResponse}
          voicePreferredLanguage={withVoiceId}
          onSucessfullAdd={handleProfileCardKey}
          editedContact={editedContact}
          onDeleteClick={onDeleteClick}
          onEditSuccess={onEditSuccess}
        ></AddContactPopup>
      )}
      {deleteModal && (
        <DeleteContactPopup
          deleteModal={deleteModal}
          onClose={onDeleteClose}
          deletedContact={deletedContact}
          onDeleteSuccess={onDeleteSuccess}
        ></DeleteContactPopup>
      )}
      {previewModal && (
        <RecentMessagePreviewPopup
          previewModal={previewModal}
          previewModalData={previewModalData}
          onClose={handlePreviewClose}
        ></RecentMessagePreviewPopup>
      )}

      {groupPreviewModal && (
        <GroupMembershipPreviewPopup
          groupPreviewModal={groupPreviewModal}
          groupPreviewModalData={groupPreviewModalData}
          onClose={handleGroupPreviewClose}
        ></GroupMembershipPreviewPopup>
      )}

      {isEditPreference && (
        <EditPreferencePopup
          isEditPreference={isEditPreference}
          preferenceValue={preferenceValue}
          onClose={handlePreferenceClose}
          onSucessfullAdd={handleProfileCardKey}
        ></EditPreferencePopup>
      )}

      <PageLayout
        title="My Dashboard"
        creditAlert={state}
        buttonBar={
          <div className="mobile-title">
            {isMobile() && (
              <>
                <div className={`mobile-title-icon-div ${isOpen && "clicked"}`}>
                  <div
                    className="mobile-title-progress-icon"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <i className="fa fa-bars-progress "></i>
                  </div>
                </div>
                {isOpen && (
                  <div className="dashboard-mobile-tooltip">
                    <Tooltip
                      placement="bottom"
                      className="gui-tooltip"
                      transition={false}
                      animation={false}
                    >
                      {state &&
                        (state.remainingCredits > 0 ? (
                          <div className="progress-container">
                            <div className="progress-container-text">
                              {state.remainingCredits} Texts/Voice Messages Left
                              This{" "}
                              {state.billingCycle === "Monthly"
                                ? "Month"
                                : "Year"}
                            </div>
                            <progress
                              value={state.remainingCredits}
                              max={state.totalCredits}
                              className="styled-progress-bar"
                            ></progress>
                          </div>
                        ) : (
                          <div className="progress-container">
                            <div className="progress-container-text-negative-credit">
                              <GuiTooltip
                                tooltip="Overage charges will apply for any new messages."
                                placement="top"
                              >
                                <i className="fa fa-exclamation-circle"></i>
                              </GuiTooltip>{" "}
                              0 Texts/Voice Messages Left This{" "}
                              {state.billingCycle === "Monthly"
                                ? "Month"
                                : "Year"}
                            </div>
                            <progress
                              value={10}
                              max={state.totalCredits}
                              className="styled-progress-bar negative-credits"
                            ></progress>
                          </div>
                        ))}
                    </Tooltip>
                  </div>
                )}
              </>
            )}
            <MessageDropdown isEditing={isEditing} />
          </div>
        }
      ></PageLayout>

      <div className={`my-dashboard ${isEditing && "overlay"}`}>
        <ProfileCard
          key={profileCardKey}
          onEditClick={handleEditToggle}
          isEditing={isEditing}
          isComponentDisabled={isComponentDisabled}
          onProfileEdit={handleProfileEdit}
          onAddContact={handleAddContact}
          onEditContact={onEditContact}
          onEditPreference={onEditPreference}
        />
        {useIsDesktop() && (
          <div className="sidebard-scrollbar">
            <RecentMessages
              key={rmCardKey}
              isEditing={isEditing}
              isComponentDisabled={isComponentDisabled}
              openPreview={openPreview}
            />
            <GroupMembership
              isEditing={isEditing}
              isComponentDisabled={isComponentDisabled}
              openGroupPreview={openGroupPreview}
            ></GroupMembership>
          </div>
        )}
        {!useIsDesktop() && <BottomNavBar />}
      </div>
    </>
  );
}
