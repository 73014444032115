import React, { useState } from "react";
import "../styles/ToggleBar.scss";

const ToggleBar = ({ onTabClick, IsActiveTab }) => {
  return (
    <div className="toggle-bar">
      <button
        className={IsActiveTab === "all" ? "tab active" : "tab"}
        onClick={() => {
          onTabClick("all");
        }}
      >
        All
      </button>
      <button
        className={IsActiveTab === "unread" ? "tab active" : "tab"}
        onClick={() => {
          onTabClick("unread");
        }}
      >
        Unread
      </button>
    </div>
  );
};

export default ToggleBar;
