import "../styles/confirm-delete-modal.scss";
import React from "react";
import { Modal } from "./modal";
import { GuiButton } from "./global-ui/gui-button";
import { Spinner } from "./loading-spinner";

export function ConfirmDeleteModal({
  dataQa,
  status,
  onDelete,
  onCancel,
  deleting,
}) {
  let text = "";
  let furtherText = "";
  let buttonText = "";

  if (status == "discard") {
    text = "Are you sure you want to discard your Message?";
    furtherText = "All unsaved changes will be lost.";
    buttonText = "Discard Message";
  } else if (status == "remove") {
    text = "Are you sure you want to do this?";
    buttonText = "Delete";
  }

  if (deleting) buttonText = <Spinner />;

  return (
    <Modal className="confirm-delete-modal">
      <h4 className="title">{text}</h4>
      {furtherText && <h6 className="further-text">{furtherText}</h6>}
      <div className="btn-container">
        <GuiButton dataQa="cancel" className="btn-outline" onClick={onCancel}>
          Cancel
        </GuiButton>
        <GuiButton dataQa="other" className="warning" onClick={onDelete}>
          {buttonText}
        </GuiButton>
      </div>
    </Modal>
  );
}
