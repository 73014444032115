import "../styles/group-properties.scss";
import React from "react";
import { GuiTextbox } from "./global-ui/gui-textbox";
import { GuiSelect } from "./global-ui/gui-select";
import { GuiCheckbox } from "./global-ui/gui-checkbox";
import { useUpdateState } from "../utilities/utilities";
import actions from "../actions/actions";
import { Modal } from "./modal";
import { GuiButton } from "./global-ui/gui-button";
import { Icon } from "./icon";
import { useOnFirstLoad } from "../utilities/hooks";
import { useSelector } from "react-redux";
import ValidationContext, { useValidation } from "./global-ui/validation";
import { FileUploadButton } from "./image-library-modal/file-upload";
import { dispatch } from "../redux/redux-config";

export function GroupProperties({ group, settings, onChange }) {
  useOnFirstLoad(actions.groups.initPage); // Ensure that locationOptions is loaded
  let { locationOptions } = useSelector((store) => store.groups);
  settings = settings || {};

  let hasAlwaysAttach =
    settings.canAlwaysAttach && group.locationId === 0 && group.groupType === 5;
  let otherLocations = locationOptions?.filter(
    (x) => x.value === 0 || x.value !== group.id
  );
  let alwaysAttachOptions = [{ value: 0, label: "None" }].concat(
    locationOptions?.filter((x) => !!x.value && x.value !== group.id) || []
  );
  let validation = useValidation(validate, [
    group,
    hasAlwaysAttach,
    otherLocations,
    alwaysAttachOptions,
  ]);

  function validate() {
    let errors = {};
    if (!group.name) errors["name"] = "Name is required";
    if (
      settings.hasGroupTypes &&
      group.groupType !== 1 &&
      !otherLocations.some((loc) => loc.value === group.locationId)
    )
      errors["locationId"] = (settings.locationName || "This") + " is required";
    return errors;
  }

  return (
    <ValidationContext.Provider value={validation}>
      <div className="group-properties">
        {/* Name & Description */}
        <GuiTextbox
          className="form-row"
          name="name"
          label="Group Name"
          value={group.name}
          onChange={onChange}
        />
        <GuiTextbox
          className="form-row"
          name="description"
          label="Description"
          value={group.description}
          onChange={onChange}
        />

        {/* Group Code */}
        {settings.hasGroupCode && (
          <GuiTextbox
            className="form-row"
            name="groupCode"
            label="Group Id"
            value={group.groupCode}
            onChange={onChange}
            format="digits"
          />
        )}

        {/* Group Type & OptIn */}
        <div className="form-row group-type-row">
          {settings.hasGroupTypes && (
            <GuiSelect
              name="groupType"
              label="Group Type"
              options={settings.groupTypes || []}
              value={group.groupType}
              onChange={onChange}
            />
          )}
          {group.groupType === 5 && (
            <GuiCheckbox
              name="allowOptIn"
              label="Allow users to opt in"
              value={group.allowOptIn}
              onChange={onChange}
            />
          )}
        </div>

        {/* Location */}
        {settings.hasGroupTypes &&
          group.groupType !== 1 &&
          otherLocations?.length > 0 && (
            <GuiSelect
              className="form-row"
              name="locationId"
              label={settings.locationName}
              options={otherLocations}
              value={group.locationId}
              onChange={onChange}
            />
          )}

        {/* Always Attach */}
        {hasAlwaysAttach && (
          <GuiSelect
            className="form-row always-attach"
            name="alwaysAttach"
            label={
              "Always attach this group to messages for this " +
              settings.locationName
            }
            options={alwaysAttachOptions}
            value={group.alwaysAttach || 0}
            onChange={onChange}
          />
        )}

        {/* Sender Info */}
        {group.groupType === 1 && (
          <>
            <GuiTextbox
              className="form-row"
              name="groupEmail"
              label="Reply-To Email"
              value={group.groupEmail}
              onChange={onChange}
            />
            <GuiTextbox
              className="form-row"
              name="groupEmailName"
              label="Email Send Name"
              value={group.groupEmailName}
              onChange={onChange}
            />
            <GuiTextbox
              className="form-row"
              name="reportingEmail"
              label="Reporting Email"
              value={group.reportingEmail}
              onChange={onChange}
            />
            <GuiTextbox
              className="form-row"
              name="reportingEmailName"
              label="Reporting Email Name"
              value={group.reportingEmailName}
              onChange={onChange}
            />
            <div className="form-row caller-id-row">
              <GuiTextbox
                name="callerId"
                label="Caller ID"
                value={group.callerId}
                onChange={onChange}
                format="phone"
              />
              <GuiTextbox
                name="emergencyCallerId"
                label="Emergency Caller ID"
                value={group.emergencyCallerId}
                onChange={onChange}
                format="phone"
              />
            </div>
          </>
        )}

        {/* Always Include */}
        {settings.canAlwaysInclude && (
          <div className="form-row">
            <GuiCheckbox
              name="alwaysInclude"
              label="Include group on all messages"
              value={group.alwaysInclude}
              onChange={onChange}
            />
          </div>
        )}

        {/* Logo */}
        {group.groupType === 1 &&
          (group.logoUrl ? (
            <div className="logo">
              <label>Logo</label>
              <img src={group.logoUrl} alt="Logo" />
              <span>
                <Icon
                  icon="trash"
                  button={true}
                  onClick={(e) => onChange("logoUrl", null)}
                />
              </span>
            </div>
          ) : (
            <div className="add-logo">
              <label>Logo</label>
              <FileUploadButton
                name="logoUrl"
                onChange={onChange}
                acceptedTypes=".jpg, .jpeg, .png"
                maxFileSizeMB={1}
              />{" "}
              No file selected
            </div>
          ))}
      </div>
    </ValidationContext.Provider>
  );
}

export function GroupPropertiesModal({ group, onClose }) {
  let [state, updateState] = useUpdateState({});
  let validation = useValidation();
  let settings = useSelector((store) => store.groups.groupSettingsForUser);
  let newGroupTemplate = useSelector((store) => store.groups.newGroupTemplate);

  useOnFirstLoad(() => {
    if (group.id > 0 || !settings) {
      actions.groups.getGroupDetails(group.id).then((result) => {
        if (!result.success) return;

        result.settings.groupTypes = result.settings.groupTypes.map((x) => ({
          value: x.id,
          label: x.name,
        }));
        if (!result.group.id) result.group.groupType = 5; // Default to "Custom"

        updateState({ updatedGroup: result.group });
      });
    } else {
      updateState({ updatedGroup: { ...newGroupTemplate, groupType: 5 } }); // Default to "Custom"
    }
  });

  function updateGroup(name, value) {
    value = value?.value || value?.value === 0 ? value.value : value; // Change dropdown option into value
    let updated = { ...state.updatedGroup, [name]: value };
    updateState("updatedGroup", updated);
  }

  async function onSave() {
    if (!validation.allValid()) return validation.setShow(true);

    updateState("saving", true);

    let group = state.updatedGroup;
    let result = await actions.groups.save(group);
    if (result && result.success) onClose(true);
    else updateState("saving", false);
  }

  return (
    <ValidationContext.Provider value={validation}>
      <Modal
        className={
          "plain-modal group-properties-modal" +
          (!state.updatedGroup ? " loading" : "")
        }
      >
        <h3>{group.id > 0 ? "Edit Group" : "Create Group"}</h3>
        <Icon icon="times" className="close" onClick={(e) => onClose(false)} />

        <GroupProperties
          group={state.updatedGroup || {}}
          settings={settings}
          onChange={updateGroup}
        />

        <div className="buttons">
          <GuiButton className="btn-outline" onClick={(e) => onClose(false)}>
            Cancel
          </GuiButton>
          <GuiButton onClick={() => onSave(state)} loading={state.saving}>
            {group.id > 0 ? "Update" : "Create"}
          </GuiButton>
        </div>
      </Modal>
    </ValidationContext.Provider>
  );
}
