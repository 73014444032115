import React, { useEffect, useRef, useState } from 'react';
import api from "../api/api";
import i18n from 'i18next';
import { appState } from "../redux/redux-config";

export default function OrgSwitcher() {
    const appAndOrgSwitcher = useRef();
    const [loading, setLoading] = useState(true); // To track loading state

    useEffect(() => {
        // Fetch the organization details when the component mounts
        const fetchUserOrganizationDetails = async () => {
            try {
                // Access token directly from appState
                const token = appState().user.token;
                if (!token) {
                    throw new Error('Token is not available');
                }
                const result = await api.user.getUserOrganizationDetails(token);
                console.log('result', result);
                if (result.IsUnityUser) {
                    // If the user is a Unity user, dynamically load the orgSwitcher script
                    const script = document.createElement('script');
                    script.id = 'appAndOrgSwitcherScript';
                    script.src = result.OrgSwitcherScript.src;
                    script.dataset.uuid = result.OrgSwitcherScript.DatasetUuid;
                    // orgSwitcherScript.dataset.currentOrgName = 'Christ the King Church';
                    script.dataset.selected = 2;  // Adjust based on your selected module
                    script.dataset.lang = 'en';//i18n.language; // Use current language
                    script.dataset.fixed = 'true';
                    script.dataset.haveRequiredFonts = 'true';
                    // Append the script to the div in the component
                    appAndOrgSwitcher.current.appendChild(script);
                } 
            } catch (error) {
                console.error('Error fetching organization details:', error);
            } finally {
                setLoading(false); // Mark loading as done
            }
        };

        fetchUserOrganizationDetails();
    }, []); // Empty array means this runs only once when the component mounts

    if (loading) {
        return <div>Loading...</div>; // Or any loading state/UI
    }

    return (
        <div style={{ width: '104px', height: '65px' }}>
            <div ref={appAndOrgSwitcher}></div>
        </div>
    );
}
