import api from "../api/api";
import { showToast } from "../components/toast";
import { appState, dispatch } from "../redux/redux-config";
import { AlertStatus, AlertType, Path } from "../utilities/constants";
import navigate from "../utilities/navigation";

const alerts = {
  showCreateAlert: function (isEmergency, groups, users) {
    let alert = {
      ...blankAlert,
      allGroups: isEmergency,
      isEmergency: isEmergency,
    };
    if (groups) alert.groups = groups;
    if (users) alert.users = users;

    dispatch("NEW_ALERT", alert);
    navigate(isEmergency ? Path.SendEmergency : Path.Send);
  },

  showEditAlert: function (alert) {
    let data = {
      id: alert.status !== AlertStatus.Sent ? alert.id : 0,
      name: alert.name,
      isScheduled: alert.scheduledDate ? true : false,
      scheduledDate:
        (alert.status !== AlertStatus.Sent ? alert.scheduledDate : null) ||
        null,
      groups: alert.groups,
      allGroups: false, //
      isEmergency: alert.isEmergency,
      isDraft: alert.status === AlertStatus.Draft,
      attachments: alert.attachments || [],
    };

    if (alert.alertType === AlertType.Text) {
      data.includeText = true;
      data.smsMessage = alert.message;
    } else if (alert.alertType === AlertType.Email) {
      data.includeEmail = true;
      data.emailMessage = alert.message;
    } else if (alert.alertType === AlertType.Voice) {
      data.includeVoice = true;
      data.voiceFileIdentifier = alert.voiceFileIdentifier;
      if (!alert.message) {
        // A recording
        data.voiceFileUrl = alert.voiceFileUrl;
      } else {
        // Text-To-Speech
        data.t2sMessage = alert.message;
        data.t2sUrl = alert.voiceFileUrl;
      }
    }

    dispatch("NEW_ALERT", data);
    navigate(Path.Send);
  },

  changeStep: function (step) {
    dispatch("ALERT_CHANGE_STEP", step);
  },

  saveAlert: async (alert) => {
    let token = appState().user.token;

    // If there's a recording, upload it before saving the Alert
    if (!alert.voiceFileIdentifier && alert.voiceRecording) {
      let uploadResult = await api.file.uploadRecording(
        token,
        alert.voiceRecording,
        alert.voiceRecording.type
      );
      if (uploadResult.success) alert.voiceFileUrl = uploadResult.url;
      else return;
    }

    dispatch("ALERT_SAVING", true);
    let result = await api.alerts.save(token, alert);
    dispatch("ALERT_SAVING", false);

    if (result.success) {
      dispatch("RESET_MY_ALERTS");

      if (alert.isDraft) {
        showToast("success", "Your Draft has been saved.");
        navigate(Path.Messages);
      } else if (alert.scheduledDate) {
        showToast("success", "Message scheduled");
        navigate(Path.Messages);
      } else {
        showToast("success", "Message sent.");
        navigate(Path.Messages);
      }
    }
  },

  showDetails: function (alertId) {
    navigate("/alert?id=" + alertId);
    dispatch("NEW_ALERT", { ...blankAlert });
  },

  loadAlertDetails: async (alertId) => {
    let token = appState().user.token;
    let result = await api.alerts.getDetails(token, alertId);
    if (result.success) dispatch("NEW_ALERT", result);
  },

  deleteAlert: async (alertId) => {
    let token = appState().user.token;
    dispatch("ALERT_SAVING", true);
    return await api.alerts.deleteAlert(token, alertId);
  },

  textToSpeech: async (message) => {
    let token = appState().user.token;
    return await api.file.textToSpeech(token, message);
  },

  loadTemplates: async (alertType) => {
    let token = appState().user.token;
    let result = await api.alerts.templates(token, alertType);
    if (result.success) {
      result.templates.forEach((t) => {
        t.value = t.id;
        t.label = t.name;
      });
      dispatch("LOAD_TEMPLATES", result.templates);
    }
  },
};
export default alerts;

export function alertReducer(state, action) {
  if (state === undefined) return initialState;

  switch (action.type) {
    case "NEW_ALERT":
      return {
        ...state,
        current: action.payload,
        step: 0,
      };

    case "ALERT_CHANGE_STEP":
      return {
        ...state,
        step: action.payload,
      };

    case "ALERT_SAVING":
      return {
        ...state,
        saving: action.payload,
      };

    case "UPDATE_NEW_ALERT":
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload,
        },
      };

    case "LOAD_TEMPLATES":
      return {
        ...state,
        templates: action.payload,
      };

    case "REMOVE_ATTACHMENT":
      return {
        ...state,
        current: {
          ...state.current,
          attachments: state.current.attachments.filter(
            (a) => a !== action.payload
          ),
        },
      };

    case "LOGOUT":
      return initialState;

    default:
      return state;
  }
}

const blankAlert = {
  name: "",
  isScheduled: false,
  subject: "",
  htmlMessage: "",
  allGroups: false,
  groups: [],
  isEmergency: false,
  attachments: [],
};

const initialState = {
  current: { ...blankAlert },
  step: 0,
  templates: [],
  saving: false,
};
