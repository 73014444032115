import "../styles/top-bar.scss";
import React, { useRef, useState } from "react";
import { dispatch } from "../redux/redux-config";
import { useSelector } from "react-redux";
import actions from "../actions/actions";
import Popover from "./popover";
import { useEffect } from "react";
import { useUpdateState } from "../utilities/utilities";
import navigate from "../utilities/navigation";
import { Path } from "../utilities/constants";
import OrgSwitcher from "./org-switcher";
import api from "../api/api";
import { appState } from "../redux/redux-config";

export default function TopBar() {
  let user = useSelector((store) => store.user);
  let isSidebarExpanded = useSelector((store) => store.page.sidebarExpanded);
  let updateVersion = useSelector((store) => store.page.updateVersion);
  let [state, updateState] = useUpdateState({});
  let nameRef = useRef();

  let { showHelpMenu, showSettingsMenu, showUserMenu } = state;
  let showUpdateAvailable =
    updateVersion && updateVersion !== state.updateVersionDismissed;

  const [isUnityUser, setIsUnityUser] = useState(false); // Track Unity user status

  useEffect(() => {
    // Check if the user is a Unity user
    const checkIfUnityUser = async () => {
      try {
        const token = appState().user.token;
        const result = await api.user.getUserOrganizationDetails(token);
        setIsUnityUser(result.IsUnityUser);
      } catch (error) {
        console.error("Error fetching organization details:", error);
      }
    };

    checkIfUnityUser();
  }, [user]);

  if (user.loaded === undefined) setTimeout(actions.user.getUserInfo, 0);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.style.visibility = "hidden";
      for (let i = 25; i > 0; i--) {
        nameRef.current.style.fontSize = i + "px";
        if (nameRef.current.scrollWidth <= nameRef.current.clientWidth) break;
      }

      nameRef.current.style.visibility = "visible";
    }

    function onResize() {
      updateState({});
    }
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [user.customerName, window.outerWidth, state.logoLoaded]);

  // Add module and org uuid and id once we have values .
  // useEffect(() => {
  // let script = document.createElement("script");
  // script.src = "https://account.dev1.myamplify.io/orgSwitcher/orgSwitcher.js";
  // script.id = "orgSwitcherScript";
  // script.dataset.uuid = "9DE12879-8AC2-42F1-9DCA-183B37475D13";
  // script.dataset.selected = "1";

  // hgsAppSwitcher.current.appendChild(script);
  // }, []);
  function onImageLoad() {
    updateState({ logoLoaded: true });
  }

  function onImageError() {
    updateState({ logoLoaded: false });
  }

  function goTo(e, path) {
    e.preventDefault();
    e.stopPropagation();
    updateState({ showHelpMenu: false, updateVersionDismissed: updateVersion });
    navigate(path);
  }

  function goToTraining(e) {
    e.preventDefault();
    e.stopPropagation();
    window.open(user.trainingVideoUrl);
  }

  return (
    <div className="top-bar">
      <div
        className="mobile-menu-button"
        onClick={() => dispatch("TOGGLE_SIDE_BAR", !isSidebarExpanded)}
      >
        <i className="fas fa-solid fa-bars"></i>
      </div>
      {isUnityUser && (
        <div className="org-and-logo">
          <div className="org-switcher-container">
            <OrgSwitcher /> {/* Only show OrgSwitcher if Unity user */}
          </div>
        </div>
      )}

      {/* Old design if not a Unity user */}
      {!isUnityUser && (
        <>
          <div
            className="client-logo"
            style={{ display: state.logoLoaded ? undefined : "none" }}
          >
            <div>
              <img
                src={user.logoUrl}
                onLoad={onImageLoad}
                onError={onImageError}
                alt="Logo"
              />
            </div>
          </div>

          {!state.logoLoaded && (
            <div className="client-name" ref={nameRef}>
              {user.customerName}
            </div>
          )}
        </>
      )}

      <div className="right-buttons">
        <div
          className="help"
          onClick={() => updateState({ showHelpMenu: true })}
        >
          <i className="fas fa-question-circle"></i>
          <Popover
            show={showHelpMenu && !showUpdateAvailable}
            onHide={() => updateState({ showHelpMenu: false })}
          >
            <div className="menu-item" onClick={goToTraining}>
              Training Videos
            </div>
            <div className="menu-item" onClick={(e) => goTo(e, Path.Help)}>
              Contact Us
            </div>
          </Popover>
          <Popover
            show={showUpdateAvailable}
            onHide={() =>
              updateState({ updateVersionDismissed: updateVersion })
            }
          >
            <div className="menu-item" onClick={(e) => goTo(e, Path.Help)}>
              An Update is Available!
            </div>
          </Popover>
        </div>

        {/* Settings */}
        <div
          className="settings-cog"
          onClick={(e) => goTo(e, Path.SettingsSms)}
        >
          <i className="fa-solid fa-cog"></i>
        </div>
        {/*<div className="settings-cog" onClick={() => updateState({ showSettingsMenu: !showSettingsMenu })}>*/}
        {/*    <i className="fa-solid fa-cog"></i>*/}
        {/*    <Popover show={showSettingsMenu} onHide={() => updateState({ showSettingsMenu: false })}>*/}
        {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsVoice)}>Voice Preferences</div>*/}
        {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsSms)}>SMS Preferences</div>*/}
        {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsLanguage)}>Language Settings</div>*/}
        {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsContact)}>Contact Preferences</div>*/}
        {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsPhoneCode)}>Phone Code</div>*/}
        {/*    </Popover>*/}
        {/*</div>*/}

        {/* Account */}
        <div
          className="user-icon"
          onClick={() => updateState({ showUserMenu: !showUserMenu })}
        >
          <span id="account">
            {user.firstName?.substr(0, 1) + user.lastName?.substr(0, 1) ||
              "\u00A0"}
          </span>
          <Popover
            show={showUserMenu}
            onHide={() => updateState({ showUserMenu: false })}
          >
            {/*<div className="menu-item" onClick={e => goTo(e, Path.Profile)}>My Profile</div>*/}
            {/*{!user.isAmplify &&*/}
            {/*    <div className="menu-item" onClick={e => goTo(e, Path.MyAccount)}>My Account</div>*/}
            {/*}*/}
            <div className="menu-item" onClick={actions.user.logout}>
              Log Out
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
}
