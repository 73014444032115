import React, { useState } from "react";
import "../styles/rm-preview-popup.scss";
import { useSelector } from "react-redux";
import { GuiButton } from "../components/global-ui/gui-button";
import AudioPlayer from "../components/audio-player";
import { convertStringToJSX, useUpdateState } from "../utilities/utilities";
import { useOnFirstLoad } from "../utilities/hooks";
import actions from "../actions/actions";
import CircularProgress from "../actions/CircularProgress";
import user from "../actions/user-actions";
const RecentMessagePreviewPopup = ({
  previewModal,
  onClose,
  previewModalData,
}) => {
  let isMobile = useSelector((store) => store.page.isMobile);
  const [loading, setLoading] = useState(true);
  let [state, updateState] = useUpdateState(() => {
    return { audioUrl: "" };
  });
  useOnFirstLoad(() => {
    const payload = {
      AlertId: previewModalData.alertId,
      IsMessageRead: true,
    };
    user.updateReadMessageProperty(payload).then((response) => {
      if (response.success) {
        if (previewModalData.messageType === "Voice") {
          convertTTS();
        } else {
          setLoading(false);
        }
      }
    });
  });
  async function convertTTS() {
    var result = await actions.alerts.textToSpeech(
      previewModalData.messageDescription
    );
    if (result.success) {
      updateState({ audioUrl: result.fileUrl });
      setLoading(false);
    }
    return result;
  }
  const onBtnClick = () => {};

  return (
    <div className={`Modal ${previewModal ? "Show" : "hide"}`}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="modal-header">
            {isMobile && (
              <span onClick={onClose} className="mr-2">
                <i className="fa fa-chevron-left close-primary-icon cursor-pointer"></i>
              </span>
            )}
            <h4>Message Preview</h4>
            {!isMobile && (
              <span onClick={onClose}>
                <i className="fa fa-close close-primary-icon cursor-pointer"></i>
              </span>
            )}
          </div>
          <div className="modal-body">
            <div className="modal-body-title">{`${previewModalData.messageType} - ${previewModalData.subject}`}</div>
            <div className="rm-modal-footer-hr-divider"></div>
            <div className="modal-body-header">
              <div className="modal-body-header-avatar">
                <div className="initials">
                  {previewModalData.senderName
                    .split(" ")
                    .map((n) => n[0])
                    .join("")}
                </div>
              </div>
              <div className="modal-body-header-title">
                <div className="modal-body-header-title-name">
                  <div className="name">{previewModalData.senderName}</div>
                  <div className="time">{previewModalData.dateTime}</div>
                </div>
                <div className="receiver-name">{`To: ${previewModalData.senderName}`}</div>
              </div>
            </div>

            <div>
              {previewModalData.messageType === "Voice" ? (
                <AudioPlayer src={state.audioUrl} autoPlay={false} />
              ) : (
                convertStringToJSX(previewModalData.messageDescription)
              )}
            </div>
          </div>
          <div className="rm-modal-footer">
            <div className="rm-modal-footer-hr-divider"></div>
            <div className="rm-modal-footer-btns">
              <GuiButton className="cancel-btn" onClick={onClose}>
                Close
              </GuiButton>

              <GuiButton className="add-btn" onClick={onBtnClick}>
                View in Messages
              </GuiButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RecentMessagePreviewPopup;
